<template>
    <div class="newConsumeDetailed">
        <div class="consume-box">
            <header>
                <ul>
                    <li>
                        <el-select
                            v-model="formInline.station_id"
                            placeholder="选择场站"
                            @change="stationChange"
                            :disabled="percentage!=100"
                        >
                            <el-option
                                v-for="(item,index) in stationData"
                                :key="index"
                                :label="item.name"
                                :value="item.station_id"
                            ></el-option>
                        </el-select>
                    </li>
                    <li>
                        <el-select
                            v-model="formInline.line_code"
                            placeholder="选择生产机组"
                            @change="lineChange"
                            :disabled="percentage!=100"
                        >
                            <el-option value="" label="全部"></el-option>
                            <el-option
                                v-for="(item,index) in lineData"
                                :key="index"
                                :label="item.line_name"
                                :value="item.line_code"
                            ></el-option>
                        </el-select>
                    </li>
                    <li>
                        <el-input v-model="formInline.strength_grade" placeholder="请输入强度等级"></el-input>
                    </li>
                    <li>
                        <el-date-picker
                            v-model="timeArray"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            format="yyyy-MM-dd HH:mm:ss"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :default-value="new Date()"
                            @change="timeChange"
                            :disabled="percentage!=100"
                            :clearable="false"
                            :editable="false"
                        >
                        </el-date-picker>
                    </li>
                    <li>
                        <el-input v-model="formInline.search" placeholder="请输入搜索内容..." :disabled="percentage!=100">
                            <template slot="append">
                                <div class="search-btn" @click="searchChange()">
                                    <i class="el-icon-search"></i>
                                </div>
                            </template>
                        </el-input>
                    </li>
                    <li>
                        <el-button
                            type="primary"
                            class="export_btn"
                            @click="exportExcelJs1"
                            :disabled="percentage!=100"
                        >
                            <i class="iconfont icondaochu"></i>
                            <span>导出excel</span>
                        </el-button>
                    </li>
                </ul>
            </header>
            <section>
                <div class="table-wrapper table-bg" id="tableWrapper" v-loading="loading">
                    <el-progress :percentage="percentage" :format="formatPercent"></el-progress>
                    <ux-grid
                        ref="plxTable"
                        :height="tableHeight"
                        stripe
                        show-overflow
                        width-resize
                        show-header-overflow="ellipsis"
                        :header-row-style="{background: '#edf0f5',color: '#022782'}"
                        :show-summary="showSummary"
                        :summary-method="getSummaries"
                    >
                        <ux-table-column type="index" title="序号" width="60"></ux-table-column>
                        <template
                            v-for="(item,index) in tabelHeader"
                        >
                            <ux-table-column
                                v-if="(item.childs || []).length > 0"
                                :title="item.field_desc"
                                align="center"
                                :key="'tabelHeader'+index"
                            >
                                <template v-if="(item.childs || []).length > 0">
                                    <ux-table-column
                                        v-for="(jtem,jIndex) in item.childs"
                                        :field="jtem.field_name"
                                        :title="jtem.field_desc"
                                        min-width="140"
                                        align="center"
                                        :key="'childHeader'+jIndex"
                                    ></ux-table-column>
                                </template>
                            </ux-table-column>
                            <ux-table-column
                                v-else
                                :field="item.field_name"
                                :title="item.field_desc"
                                :width="item.width"
                                :resizable="item.resizable"
                                :sortable="item.sortable"
                                show-header-overflow="ellipsis"
                                :key="'tabelHeader'+index"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <div class="btnsColumn" v-if="item.field_name == 'pcb_number'">
                                        <span>{{ scope.row[item.field_name] }} </span>
                                        <el-button
                                            type="primary"
                                            plain
                                            size="mini"
                                            @click="productionRecord(scope.row)"
                                        >
                                            生产原始记录
                                        </el-button>
                                        <el-button
                                            type="primary"
                                            plain
                                            size="mini"
                                            @click="openConsumptionDetails(scope.row)"
                                        >
                                            详情表单
                                        </el-button>
                                    </div>
                                    <div v-else>
                                        <span>{{ scope.row[item.field_name] }}</span>
                                    </div>
                                </template>
                            </ux-table-column>
                        </template>
                    </ux-grid>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
import currencyFun from '@/assets/js/currencyFun';
import { export_json_to_excel } from '@/assets/js/exportExcel';
export default {
    data() {
        return {
            stationData: [{ station_id: '', name: '全部' }], // 场站list
            strengthGradeData: [], // 产品标号
            lineData: [], // 生产机组
            timeArray: [], // 时间数组
            formInline: {
                station_id: '',
                line_code: '',
                strength_grade: '',
                search: '',
            },
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    },
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    },
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    },
                }],
            },
            // tableData: [],
            tabelHeader: [],
            loading: false,
            tableHeight: '',
            page: {
                pageindex: 0,
                pagesize: 10,
            },
            total: 100,
            sum_volume: 0,
            showSummary: false,
            percentage: 100, // 进度条
        };
    },
    async created() {
        await this.initTime();
        this.getAutoHeight();
        // 获取全部场站
        this.getStationData();
    },
    mounted() {},
    methods: {
        async initTime() {
            const newTimeNode = await currencyFun.getTimeNode('setting_product_time_format', this);
            if (Object.keys(newTimeNode).length === 0) {
                newTimeNode.start_time = '00:00:00';
                newTimeNode.end_time = '00:00:00';
            }
            this.timeArray = [
                util.timeFormat(new Date(new Date().getTime() - 3600 * 1000 * 24 * 1), 'yyyy-MM-dd') + ' ' + newTimeNode.start_time,
                util.timeFormat(new Date(new Date()), 'yyyy-MM-dd') + ' ' + newTimeNode.end_time,
            ];
        },
        // 格式化进度条内容
        formatPercent(percentage) {
            if (percentage > 0 && percentage < 100) {
                return `加载中...${percentage}%`;
            }
            if (percentage === 100) {
                return `加载完成${percentage}%`;
            }
        },
        scroll({ scrollTop }) {
            this.scrollTop = scrollTop;
        },
        // 选择场站触发的事件
        stationChange() {
            this.lineData = [];
            this.formInline.line_code = '';
            this.gitlineData();
            this.getTableHeader();
            this.getTableData();
        },
        // 选择生产机组触发的事件
        lineChange() {
            this.getTableHeader();
            this.getTableData();
        },
        // 获取场站list
        getStationData() {
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_user/?userId=' + this.$takeTokenParameters('Uid')
                + '&roleCode=data_filtering')
                .then(res => {
                    this.stationData.push(...res || []);
                    if (this.stationData.length > 0) {
                        this.formInline.station_id = this.stationData[0].station_id;
                        this.gitlineData();
                        this.getTableHeader();
                        this.getTableData();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取生产机组list
        gitlineData() {
            if (this.formInline.station_id) {
                this.$axios
                    .get(`/interfaceApi/production/mixstationline/${this.formInline.station_id}`)
                    .then(res => {
                        this.lineData = res;
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 获取生产标号
        getstrengthGrade() {
            this.$axios
                .get('/interfaceApi/production/dictionary/dropdownlist/004-PH0/0')
                .then(res => {
                    this.strengthGradeData = res;
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 时间选择触发的事件
        async timeChange(date) {
            if (date === null) {
                await this.initTime();
            }
            this.getTableHeader();
            this.getTableData();
        },
        // 点击搜索按钮触发的事件
        searchChange() {
            if (this.percentage === 100) {
                this.getTableHeader();
                this.getTableData();
            }
        },
        async getTableHeader() {
            if ((this.timeArray || []).length > 0) {
                this.formInline.start_time = this.timeArray[0];
                this.formInline.end_time = this.timeArray[1];
            }
            this.$axios
                .post('/interfaceApi/production/new/productconsume/fields', this.formInline)
                .then(res => {
                    this.tabelHeader = res;
                    this.tabelHeader.forEach(item => {
                        if (item.field_name === 'pcb_number') {
                            item.width = '450px';
                        } else if (['pcsj', 'task_number', 'project_name', 'customer_name'].indexOf(item.field_name) > -1) {
                            item.width = '220px';
                        } else {
                            item.width = '120px';
                        }
                    });
                })
                .catch(error => {
                    this.percentage = 100;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        async getTableData() {
            this.batchGetData();
        },
        getAutoHeight() {
            const el = document.querySelector('#tableWrapper');
            const elParent = el.parentNode;
            const pt = this.getStyle(elParent, 'paddingTop');
            const pb = this.getStyle(elParent, 'paddingBottom');
            this.$nextTick(() => {
                this.tableHeight = elParent.offsetHeight - (pt + pb) - 7;
            });
        },
        getStyle(obj, attr) {
            // 兼容IE浏览器
            const result = obj.currentStyle
                ? obj.currentStyle[attr].replace('px', '')
                : document.defaultView
                    .getComputedStyle(obj, null)[attr].replace('px', '');
            return Number(result);
        },
        // 后端导出表格 弃用
        exportExcel() {
            this.loading = true;
            // 导出表格
            if ((this.timeArray || []).length > 0) {
                this.formInline.start_time = this.timeArray[0];
                this.formInline.end_time = this.timeArray[1];
            }
            this.$axios
                .post('/interfaceApi/production/new/productconsume/exportexcel', this.formInline, { responseType: 'arraybuffer' })
                .then(res => {
                    this.loading = false;
                    const blob = new Blob([res.data], { type: 'application/octet-stream' });
                    const fileName = `生产消耗汇总${util.currentTimeFormat('yyyy-MM-dd HH:mm:ss')}.xls`;
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a');
                        elink.download = fileName;
                        elink.style.display = 'none';
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        URL.revokeObjectURL(elink.href); // 释放URL 对象
                        document.body.removeChild(elink);
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 前端导出表格
        exportExcelJs1() {
            this.$message.info('数据即将导出,请稍等~');
            const fieldMap = {};
            const header = [[], []];
            const data = [];
            // 处理表头合并
            const merge = [];
            let colIndex = 0;
            this.tabelHeader.forEach(item => {
                header[0].push(item.field_desc || '');
                if ((item.childs || []).length > 0) {
                    item.childs.forEach((child, childIndex) => {
                        fieldMap[child.field_name] = colIndex;
                        colIndex++;
                        if (childIndex > 0) {
                            header[0].push('');
                        }
                        header[1].push(child.field_desc || '');
                    });
                } else {
                    fieldMap[item.field_name] = colIndex;
                    colIndex++;
                    header[1].push('');
                }
            });
            const row = header[0];
            if (header[1].length > 0) {
                for (let colIndex = 0; colIndex < row.length; colIndex++) {
                    if (row[colIndex]) {
                        if (row[colIndex + 1] || colIndex === row.length - 1) {
                            // 纵向合并 列
                            merge.push({ s: { r: 0, c: colIndex }, e: { r: header.length - 1, c: colIndex } });
                        } else {
                            // 横向合并行
                            // eslint-disable-next-line max-depth
                            for (let endIndex = colIndex; endIndex < row.length; endIndex++) {
                                // eslint-disable-next-line max-depth
                                if (endIndex === row.length - 1 || row[endIndex++]) {
                                    merge.push({ s: { r: 0, c: colIndex }, e: { r: 0, c: endIndex } });
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            this.tableData.forEach(item => {
                const row = [];

                for (const key in fieldMap) {
                    row.push(item[key] || '');
                }
                // 处理空
                data.push(row);
            });
            const merges = merge; // 合并单元格
            const name = '生产消耗汇总' + util.currentTimeFormat('yyyy-MM-dd HH_mm_ss');
            export_json_to_excel({
                title: header[0],
                header: header[1],
                data,
                merges,
                filename: name,
                autoWidth: true,
                bookType: 'xlsx',
            });
        },
        handleSizeChange(val) {
            this.page.pagesize = val;
        },
        handleCurrentChange(val) {
            this.page.pageindex = val;
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    // sums[index] = '合计';
                    sums.push('合计');
                    return;
                }
                // if (index >= 1 && index <= 5 || index === 9) {
                //     sums[index] = '';
                //     return;
                // }
                if ( column.property === 'shch') {
                    sums[index] = '';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        return prev;

                    }, 0);
                    sums[index] = sums[index].toFixed(2);
                } else {
                    sums[index] = '';
                }
            });
            return [sums];
        },
        // 生产原始记录
        productionRecord(row) {
            this.$toast({
                title: true,
                text: '业务信息',
                type: 'eject',
                width: '80%',
                height: '90%',
                t_url: 'productionManagement/productionDeliverGoodsJournal/logList',
                extr: {
                    pcb_number: row.pcb_number,
                },
            });
        },
        // 发货单的详细表单
        openConsumptionDetails(row) {
            this.$toast({
                title: true,
                text: '发货单详情',
                type: 'eject',
                width: '85%',
                height: 'auto',
                t_url: 'productionManagement/productionConsumptionSummary/invoiceDetails',
                extr: {
                    pcb_number: row.pcb_number,
                },
            });
        },

        // 分批获取表格数据
        async batchGetData() {
            this.percentage = 0;
            // 大数据下 建议不放入data 内，双向绑定消耗性能
            this.tableData = [];
            if ((this.timeArray || []).length > 0) {
                this.formInline.start_time = this.timeArray[0];
                this.formInline.end_time = this.timeArray[1];
            }
            const pcbnumbers = await this.$axios
                .post('/interfaceApi/production/new/productconsume/pcbnumbers', this.formInline) || [];
            this.showSummary = pcbnumbers.length > 0;
            await this.getDataByPcbNumbers(pcbnumbers);
            this.$refs.plxTable.reloadData(this.tableData);
            this.percentage = 100;
        },
        // 通过发货单号批量获取数据
        async getDataByPcbNumbers(pcbnumbers = [], index = 0) {
            if (pcbnumbers.length === 0) {
                return;
            }
            let limit = 100;
            const diff = pcbnumbers.length - index;
            if (diff < limit) {
                limit = diff;
            }
            const nextIndex = index + limit;
            const limitPcbnumbers = pcbnumbers.slice(index, nextIndex);
            const data = await this.$axios
                .post('/interfaceApi/production/new/productconsume/pcbnumbers/data', limitPcbnumbers);
            this.tableData.push(...data || []);
            if (index === 0 ) {
                this.$refs.plxTable.reloadData(data);
            }
            // eslint-disable-next-line radix
            this.percentage = parseInt(nextIndex / pcbnumbers.length * 100);
            if (nextIndex < pcbnumbers.length) {
                await this.getDataByPcbNumbers(pcbnumbers, nextIndex);
            }
        },
    },
};
</script>

<style lang="stylus" scope>
     .el-progress
        .el-progress-bar
            padding-right 0;
            .el-progress-bar__outer
                height 0.05rem;
        .el-progress__text
            position: absolute;
            top: -0.1rem;
            right: 0.03rem;
    .table-wrapper
        height 100%
        table
            th,td
                height 0.4rem!important;
                text-align center
        .elx-table--empty-content
            width 100%
            height 100%;
            font-size 0
            background url('../../assets/images/default/list_nothing.png') center no-repeat;
            background-size auto 70%
    .newConsumeDetailed
        .consume-box
            width 100%
            height 100%
            background #ffffff
            display flex
            flex-direction column
            header
                padding 0.1rem 0.1rem 0 0.1rem
                ul
                    display flex
                    height 100%
                    li
                        margin-right 0.1rem
                        .el-date-editor
                            width 4rem !important
                            height 0.36rem;
                            .el-input__icon
                                line-height 0.3rem
                        .el-input-group__append
                            background #1577d2
                            width 0.6rem
                            border none
                            padding 0
                            cursor pointer
                            .search-btn
                                border none
                                background #1577d2
                                width 100%;
                                cursor pointer
                                text-align center
                                i
                                    color #ffffff
                        .export_btn
                             border: none;
                             height: 0.36rem;
                             font-size: 0.14rem;
                             padding: 0 0.1rem;
                             min-width: 0.36rem;
                             line-height: 0.36rem;
                             cursor: pointer;
                             border-radius: 2px;
                             background: #f1f4f7;
                             color: #2978ff;
                             text-align: center;
            section
                flex 1
                padding 0.1rem
                overflow hidden
            .page-wrapper
                text-align right
                margin-top 0.1rem
                padding-bottom 0.1rem


</style>
